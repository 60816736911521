import { IncomingMessage, ServerResponse } from 'http'
import { useMemo } from 'react'
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  from,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import * as Sentry from '@sentry/nextjs'
import { BLOG_ENDPOINT, GIGA_CHAT_ENDPOINT } from '../lib/constants'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

type ResolverContext = {
  req?: IncomingMessage
  res?: ServerResponse
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      const errorBody = `[GraphQL error]: Message: ${JSON.stringify(
        message,
        null,
        2
      )}, Location: ${JSON.stringify(
        locations,
        null,
        2
      )}, Path: ${JSON.stringify(path, null, 2)}`

      console.info(errorBody)
      Sentry.captureException(errorBody)
    })
  if (networkError) {
    const errorBody = `[Network error]: ${networkError}`

    console.error(errorBody)
    Sentry.captureException(errorBody)
  }
})

const retryLink = new RetryLink({ attempts: { max: 2 }, delay: { max: 10e3 } })

const endpoint = new HttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
})

const blogEndpoint = new HttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_BLOG_ENDPOINT,
})

const gigaChatEndpoint = new HttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_GIGA_CHAT_ENDPOINT,
})

const mergeHandle = (existing, incoming) => {
  return {
    ...existing,
    ...incoming,
    nodes: [...(existing?.nodes || []), ...(incoming?.nodes || [])],
  }
}

function createApolloClient(context?: ResolverContext) {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([
      retryLink,
      errorLink,
      ApolloLink.split(
        (operation) => operation.getContext().clientName === BLOG_ENDPOINT,
        blogEndpoint,
        ApolloLink.split(
          (operation) =>
            operation.getContext().clientName === GIGA_CHAT_ENDPOINT,
          gigaChatEndpoint,
          endpoint
        )
      ),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            _posts: {
              keyArgs: (args) => {
                if (args?.where?.tag_slug?.in || args?.where?.slug?.in) {
                  return ['take', 'where']
                }

                return ['take']
              },
              merge: mergeHandle,
            },
            _gigaArticles: {
              keyArgs: ['take', 'where'],
              merge: mergeHandle,
            },
          },
        },
      },
    }),
  })
}

export function initializeApollo(
  initialState: any = null,
  // Pages with Next.js data fetching methods, like `getStaticProps`, can send
  // a custom context which will be used by `SchemaLink` to server render pages
  context?: ResolverContext
) {
  const _apolloClient = apolloClient ?? createApolloClient(context)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    })

    _apolloClient.cache.restore(data)
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function useApollo(initialState: any) {
  const store = useMemo(() => initializeApollo(initialState), [initialState])
  return store
}
